import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Img from 'gatsby-image';

import { ThemeProvider } from 'styled-components';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';
import axios from 'axios';

import { growl } from '../redux/actions/notifications/actionCreators';

import Layout from '../layouts/mainLayout';
import HeroImage from '../components/heroImage';
import {
  TextInput,
  SelectInput,
  StateSelectInput,
  TextareaInput,
  PrimaryButton,
} from '../styles/forms';
import { Table } from '../styles/tables';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import {
  Wrapper,
  HeroImageContentWrapper,
} from '../styles/pages/employment-application';

import config from '../config';
import theme from '../styles/theme';

export const query = graphql`
  query EmploymentApplicationQueryOld {
    hero: file(relativePath: { regex: "/deluxe-clean-hero-image/" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const hero = data => {
  if (!data) {
    return <div />;
  }
  return (
    <HeroImage
      backdrop={false}
      image={<Img fluid={data.hero.childImageSharp.fluid} alt="" />}
    >
      <HeroImageContentWrapper>
        <h1>Employment Application</h1>
        <p>Fill out the application below.</p>
      </HeroImageContentWrapper>
    </HeroImage>
  );
};

class EmploymentApplication extends Component {
  state = {
    phoneNumbers: [],
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Layout hero={hero(this.props.data)}>
          <Wrapper>
            <Formik
              initialValues={{
                firstName: '',
                middleName: '',
                lastName: '',
                streetAddress: '',
                streetAddressTwo: '',
                city: '',
                state: '',
                zipCode: '',
                emailAddress: '',
                firstDayAvailable: new Date(),
                availableAnyTime: false,
                availableMonday: false,
                availableTuesday: false,
                availableWednesday: false,
                availableThursday: false,
                availableFriday: false,
                availableSaturday: false,
                availableSunday: false,
                highSchoolGedOrDiploma: false,
                currentlyInCollege: false,
                graduatedCollege: false,
                fullTimeOrPartTimePreferred: 'full',
                authorizedToWork: false,
                convictedOfFelony: false,
                felonyExplanation: '',
                cleanDrivingRecord: false,
                educationHistory: '',
                skills: '',
                references: '',
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required('First name is required.'),
                middleName: Yup.string(),
                lastName: Yup.string().required('Last name is required.'),
                streetAddress: Yup.string().required('Address is required.'),
                streetAddressTwo: Yup.string(),
                city: Yup.string().required('City is required.'),
                state: Yup.string().required('State is required.'),
                zipCode: Yup.string().required('Zip code is required.'),
                emailAddress: Yup.string()
                  .email('Must be a valid email address.')
                  .required('Email address is required.'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                const application = pick(values, [
                  'firstName',
                  'middleName',
                  'lastName',
                  'emailAddress',
                  'firstDayAvailable',
                  'availableAnyTime',
                  'highSchoolGedOrDiploma',
                  'currentlyInCollege',
                  'graduatedCollege',
                  'fullTimeOrPartTimePreferred',
                  'authorizedToWork',
                  'convictedOfFelony',
                  'felonyExplanation',
                  'cleanDrivingRecord',
                  'educationHistory',
                  'skills',
                  'references',
                ]);
                application.address = pick(values, [
                  'streetAddress',
                  'streetAddressTwo',
                  'city',
                  'state',
                  'zipCode',
                ]);
                application.phoneNumbers = this.state.phoneNumbers;
                application.daysAvailable = {
                  monday: values.availableMonday,
                  tuesday: values.availableTuesday,
                  wednesday: values.availableWednesday,
                  thursday: values.availableThursday,
                  friday: values.availableFriday,
                  saturday: values.availableSaturday,
                  sunday: values.availableSunday,
                };

                axios
                  .post(
                    `${config.backendServer}/employment-applications`,
                    application
                  )
                  .then(res => {
                    growl({
                      message: 'Your application has been submitted',
                      type: 'success',
                    });
                  })
                  .catch(err => {
                    console.error(err);
                    growl({
                      message:
                        'There was a problem submitting your application.  Please try again.',
                      type: 'danger',
                    });
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
              }) => (
                <div className="form__container">
                  <Form>
                    <p>Start by telling us your full name.</p>
                    <label>
                      <div>First name</div>
                      <TextInput
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />
                    </label>
                    <label>
                      <div>Middle name</div>
                      <TextInput
                        type="text"
                        name="middleName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.middleName}
                      />
                    </label>
                    <label>
                      <div>Last name</div>
                      <TextInput
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                    </label>
                    <p>Now we need to know your physical address.</p>
                    <label>
                      <div>Street address</div>
                      <TextInput
                        type="text"
                        name="streetAddress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.streetAddress}
                      />
                    </label>
                    <label>
                      <div>Street address (Line 2)</div>
                      <TextInput
                        type="text"
                        name="streetAddressTwo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.streetAddressTwo}
                      />
                    </label>
                    <label>
                      <div>City</div>
                      <TextInput
                        type="text"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                      />
                    </label>
                    <label>
                      <div>State</div>
                      <StateSelectInput
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.state}
                        placeholder="Select a state"
                      />
                    </label>
                    <label>
                      <div>Zip code</div>
                      <TextInput
                        type="text"
                        name="zipCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zipCode}
                      />
                    </label>
                    <div className="phone-number-section">
                      <p>Please provide at least one phone number below.</p>
                      {this.state.phoneNumbers.length > 0 && (
                        <Table>
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.phoneNumbers.map(phoneNumber => (
                              <tr key={phoneNumber.number}>
                                <td>{capitalize(phoneNumber.numberType)}</td>
                                <td>{phoneNumber.number}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                      <a
                        href="#add-phone-number"
                        onClick={ev => {
                          ev.preventDefault();
                          this.setState({ showAddPhoneNumberForm: true });
                        }}
                      >
                        Add phone number
                      </a>
                      {this.state.showAddPhoneNumberForm && (
                        <div>
                          <SelectInput id="form__phone-number-type">
                            <option value="main">Main</option>
                            <option value="mobile">Mobile</option>
                            <option value="home">Home</option>
                            <option value="work">Work</option>
                            <option value="other">Other</option>
                          </SelectInput>
                          <TextInput id="form__phone-number" />
                          <div>
                            <a
                              href="#save"
                              onClick={ev => {
                                ev.preventDefault();
                                const newEntry = {
                                  numberType: document.querySelector(
                                    '#form__phone-number-type'
                                  ).value,
                                  number: document.querySelector(
                                    '#form__phone-number'
                                  ).value,
                                };

                                this.setState(prevState => ({
                                  phoneNumbers: prevState.phoneNumbers.concat(
                                    newEntry
                                  ),
                                  showAddPhoneNumberForm: false,
                                }));
                              }}
                            >
                              Save
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <label>
                      <div>Email address</div>
                      <TextInput
                        name="emailAddress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.emailAddress}
                      />
                    </label>
                    <div>
                      <p>When are you available to start?</p>
                      {DatePicker && typeof window !== 'undefined' && (
                        <DatePicker
                          inline
                          selected={values.firstDayAvailable}
                          onChange={date => {
                            setFieldValue('firstDayAvailable', date);
                          }}
                        />
                      )}
                    </div>
                    <div>
                      <p>Do you have open availability throughout the day?</p>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="availableAnyTime"
                          type="radio"
                          value={true}
                          checked={values.availableAnyTime === true}
                          onChange={() => {
                            setFieldValue('availableAnyTime', true);
                          }}
                          onBlur={handleBlur}
                        />
                        Yes
                      </label>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="availableAnyTime"
                          type="radio"
                          value={false}
                          checked={values.availableAnyTime === false}
                          onChange={() => {
                            setFieldValue('availableAnyTime', false);
                          }}
                          onBlur={handleBlur}
                        />
                        No
                      </label>
                      <div>
                        <p>What days are you available to work?</p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="checkbox"
                            name="availableMonday"
                            checked={values.availableMonday}
                            value={values.availableMonday}
                            onChange={handleChange}
                          />
                          Monday
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="checkbox"
                            name="availableTuesday"
                            checked={values.availableTuesday}
                            value={values.availableTuesday}
                            onChange={handleChange}
                          />
                          Tuesday
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="checkbox"
                            name="availableWednesday"
                            checked={values.availableWednesday}
                            value={values.availableWednesday}
                            onChange={handleChange}
                          />
                          Wednesday
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="checkbox"
                            name="availableThursday"
                            checked={values.availableThursday}
                            value={values.availableThursday}
                            onChange={handleChange}
                          />
                          Thursday
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="checkbox"
                            name="availableFriday"
                            checked={values.availableFriday}
                            value={values.availableFriday}
                            onChange={handleChange}
                          />
                          Friday
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="checkbox"
                            name="availableSaturday"
                            checked={values.availableSaturday}
                            value={values.availableSaturday}
                            onChange={handleChange}
                          />
                          Saturday
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="checkbox"
                            name="availableSunday"
                            checked={values.availableSunday}
                            value={values.availableSunday}
                            onChange={handleChange}
                          />
                          Sunday
                        </div>
                        <button
                          className="check-all-button"
                          onClick={ev => {
                            ev.preventDefault();

                            setFieldValue('availableMonday', true);
                            setFieldValue('availableTuesday', true);
                            setFieldValue('availableWednesday', true);
                            setFieldValue('availableThursday', true);
                            setFieldValue('availableFriday', true);
                            setFieldValue('availableSaturday', true);
                            setFieldValue('availableSunday', true);
                          }}
                        >
                          Check all
                        </button>
                      </div>
                    </div>
                    <div>
                      <p>Do you have a high school diploma or GED?</p>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="highSchoolGedOrDiploma"
                          type="radio"
                          value={true}
                          checked={values.highSchoolGedOrDiploma === true}
                          onChange={() => {
                            setFieldValue('highSchoolGedOrDiploma', true);
                          }}
                          onBlur={handleBlur}
                        />
                        Yes
                      </label>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="highSchoolGedOrDiploma"
                          type="radio"
                          value={false}
                          checked={values.highSchoolGedOrDiploma === false}
                          onChange={() => {
                            setFieldValue('highSchoolGedOrDiploma', false);
                          }}
                          onBlur={handleBlur}
                        />
                        No
                      </label>
                    </div>
                    <div>
                      <p>Are you currently in college?</p>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="currentlyInCollege"
                          type="radio"
                          value={true}
                          checked={values.currentlyInCollege === true}
                          onChange={() => {
                            setFieldValue('currentlyInCollege', true);
                          }}
                          onBlur={handleBlur}
                        />
                        Yes
                      </label>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="currentlyInCollege"
                          type="radio"
                          value={false}
                          checked={values.currentlyInCollege === false}
                          onChange={() => {
                            setFieldValue('currentlyInCollege', false);
                          }}
                          onBlur={handleBlur}
                        />
                        No
                      </label>
                    </div>
                    <div>
                      <p>Have you graduated college?</p>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="graduatedCollege"
                          type="radio"
                          value={true}
                          checked={values.graduatedCollege === true}
                          onChange={() => {
                            setFieldValue('graduatedCollege', true);
                          }}
                          onBlur={handleBlur}
                        />
                        Yes
                      </label>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="graduatedCollege"
                          type="radio"
                          value={false}
                          checked={values.graduatedCollege === false}
                          onChange={() => {
                            setFieldValue('graduatedCollege', false);
                          }}
                          onBlur={handleBlur}
                        />
                        No
                      </label>
                    </div>
                    <div>
                      <p>
                        Would your prefer full-time employment or part-time
                        employment?
                      </p>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="fullTimeOrPartTimePreferred"
                          type="radio"
                          value={true}
                          checked={
                            values.fullTimeOrPartTimePreferred === 'full'
                          }
                          onChange={() => {
                            setFieldValue(
                              'fullTimeOrPartTimePreferred',
                              'full'
                            );
                          }}
                          onBlur={handleBlur}
                        />
                        Full time
                      </label>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="fullTimeOrPartTimePreferred"
                          type="radio"
                          value={false}
                          checked={
                            values.fullTimeOrPartTimePreferred === 'part'
                          }
                          onChange={() => {
                            setFieldValue(
                              'fullTimeOrPartTimePreferred',
                              'part'
                            );
                          }}
                          onBlur={handleBlur}
                        />
                        Part time
                      </label>
                    </div>
                    <div>
                      <div style={{ marginTop: '48px', marginBottom: '48px' }}>
                        <input
                          type="checkbox"
                          name="authorizedToWork"
                          value={values.authorizedToWork}
                          onChange={handleChange}
                        />
                        I certify that I am authorized to work in the United
                        States.
                      </div>
                    </div>
                    <div>
                      <p>Have you ever been convicted of a felony?</p>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="convictedOfFelony"
                          type="radio"
                          value={true}
                          checked={values.convictedOfFelony === true}
                          onChange={() => {
                            setFieldValue('convictedOfFelony', true);
                          }}
                          onBlur={handleBlur}
                        />
                        Yes
                      </label>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="convictedOfFelony"
                          type="radio"
                          value={false}
                          checked={values.convictedOfFelony === false}
                          onChange={() => {
                            setFieldValue('convictedOfFelony', false);
                          }}
                          onBlur={handleBlur}
                        />
                        No
                      </label>
                    </div>
                    {values.convictedOfFelony === true && (
                      <div style={{ marginTop: '24px' }}>
                        <label>
                          Explain the felony conviction
                          <TextareaInput
                            name="felonyExplanation"
                            value={values.felonyExplanation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                    )}
                    <div>
                      <p>Do you have a clean driving record?</p>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="cleanDrivingRecord"
                          type="radio"
                          value={true}
                          checked={values.cleanDrivingRecord === true}
                          onChange={() => {
                            setFieldValue('cleanDrivingRecord', true);
                          }}
                          onBlur={handleBlur}
                        />
                        Yes
                      </label>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          name="cleanDrivingRecord"
                          type="radio"
                          value={false}
                          checked={values.cleanDrivingRecord === false}
                          onChange={() => {
                            setFieldValue('cleanDrivingRecord', false);
                          }}
                          onBlur={handleBlur}
                        />
                        No
                      </label>
                    </div>
                    <div style={{ marginTop: '24px' }}>
                      <label>
                        Please describe your education history.
                        <TextareaInput
                          name="educationHistory"
                          value={values.educationHistory}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                    </div>
                    <div style={{ marginTop: '24px' }}>
                      <label>
                        Please tell us about your skills.
                        <TextareaInput
                          name="skills"
                          value={values.skills}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                    </div>
                    <div style={{ marginTop: '24px' }}>
                      <label>
                        Please tell us any references you'd like to share.
                        <TextareaInput
                          name="references"
                          value={values.references}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                    </div>
                    <PrimaryButton type="submit" disabled={isSubmitting}>
                      Submit application
                    </PrimaryButton>
                  </Form>
                </div>
              )}
            </Formik>
          </Wrapper>
        </Layout>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ message, type }) => dispatch(growl({ message, type })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmploymentApplication);

import Styled from 'styled-components';

const Table = Styled.table`
  min-width: 260px;
  border-collapse: collapse;
  margin: 24px 0;

  th, td {
    padding: 18px 24px;
  }

  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 80%;
    letter-spacing: 1.2px;
  }

  td {
    color: #555;
  }

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, .05);
  }

  tbody tr:last-child {
    border-bottom: none;
  }
`;

export { Table };

import Styled from 'styled-components';

const Wrapper = Styled.div`
  .form__container {
    max-width: 480px;
    margin: 0 auto;
  }

  p {
    margin: 24px 0;
  }

  label {
    display: block;
  }

  a {
    color: #209CEE;
    text-decoration: none;
    transition: all .3s ease;

    &:hover {
      color: #007CCE;
    }
  }

  input[type="radio"] {
    margin: 6px 12px 6px 0;
  }

  input[type="checkbox"] {
    margin: 6px 12px 6px 0;
  }

  .phone-number-section {
    margin-bottom: 30px;
    margin-top: 30px;
    background-color: #FFFFF0;
    padding: 12px 0;
    border-left: 6px solid #ECC94B;

    & > * {
      max-width: 80%;
      margin: 0 auto;
    }

    p {
      margin-top: 0;
    }
  }

  .check-all-button {
    border: none;
    background: none;
    color: ${({ theme }) => theme.primary.colors.lightBlue.base};
    padding: 0;
    margin-top: 12px;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      color: ${({ theme }) => theme.primary.colors.lightBlue.darkest};
    }
  }
`;

const HeroImageContentWrapper = Styled.div`
  background-color: rgba(0, 0, 0, .3);
  width: 600px;
  padding: 12px;
  box-sizing: border-box;
`;

export { Wrapper, HeroImageContentWrapper };
